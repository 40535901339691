import { Type } from 'class-transformer';
import ConversationMessageOriginModel from '@/models/crm/conversation-message-origin.model';
import ConversationMessageStatusModel from '@/models/crm/conversation-message-status.model';
import ConversationMessageTypeModel from '@/models/crm/conversation-message-type.model';
import ConversationMessageContentModel from '@/models/crm/conversation-message-content.model';

export default class ConversationMessageModel {
  id!: number;

  messageId!: string;

  message!: string;

  contactNumber!: string;

  contactName!: string;

  @Type(() => Date)
  date!: Date;

  statusDate!: string;

  retryable!: boolean;

  errorCode!: number;

  errorMessage!: string;

  developerMessage!: string;

  forwarded!: boolean;

  waConversationId!: number;

  @Type(() => ConversationMessageModel)
  reply?: ConversationMessageModel;

  @Type(() => ConversationMessageOriginModel)
  origin!: ConversationMessageOriginModel;

  @Type(() => ConversationMessageTypeModel)
  type!: ConversationMessageTypeModel;

  @Type(() => ConversationMessageStatusModel)
  status!: ConversationMessageStatusModel;

  @Type(() => ConversationMessageContentModel)
  content!: ConversationMessageContentModel;
}

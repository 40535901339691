export default class CityModel {
  codIBGE!: string;

  nome!: string;

  siglaUF!: string;

  nomeUF!: string;

  pais!: string;

  populacao?: number;
}

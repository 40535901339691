export default class UserContactInfo {
  id!: number;

  nome!: string;

  email!: string;

  emailUsuario!: string;

  assinaturaEmail!: string;

  nomeEmpresa!: string;

  usuario!: string;

  departamentos!: string;
}

import { Type, plainToClass } from 'class-transformer';
import { ClientStatusEnum } from '@/enums/crm/client-status.enum';
import ClientGroupModel from '@/models/crm/client-group.model';
import CityModel from '@/models/city.model';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import RepresentativeModel from '@/models/crm/representative.model';
import LeadModel from '@/models/crm/lead.model';
import ContactChannelModel from '@/models/crm/contact-channel.model';

export default class ClientModel {
  type: ClientTypeEnum = ClientTypeEnum.Client;

  id!: number;

  codCliente!: string;

  cnpjCpf!: string;

  nome!: string;

  nomeFantasia!: string;

  situacao?: ClientStatusEnum;

  inadimplente?: boolean;

  diasInadimplente?: string;

  endRua?: string;

  endBairro?: string;

  endNumero?: string;

  endComplemento?: string;

  endCEP?: string;

  tipo?: string;

  inscEstadual?: string;

  telefone?: string;

  estado?: string;

  cidade?: string;

  municipio?: CityModel;

  @Type(() => Date)
  dataCadastro?: Date;

  codGrupoEconomico?: string;

  nomeGrupoEconomico?: string;

  email?: string;

  emailNFE?: string;

  celular?: string;

  facebook?: string;

  instagram?: string;

  @Type(() => Date)
  dataUltimaCompra?: Date;

  diasUltimaCompra?: string;

  codRepres?: string;

  nomeRepres?: string;

  codAtendente?: string;

  nomeAtendente?: string;

  observacao?: string;

  @Type(() => ClientGroupModel)
  listClientesGrupo?: ClientGroupModel[];

  limiteCredito?: number;

  totalVlrPedidosAberto?: number;

  totalVlrTitulosAberto?: number;

  get representante(): RepresentativeModel {
    return plainToClass(RepresentativeModel, {
      codigo: this.codRepres,
      nome: this.nomeRepres,
    });
  }

  @Type(() => LeadModel)
  lead!: LeadModel;

  @Type(() => ContactChannelModel)
  formaCaptacao!: ContactChannelModel;

  prospectId?: number;

  particularidades!: string;

  atendenteChave!: number | null;

  clienteId?: number;

  flagCliente!: number;

  atendenteResponsavel?: string;

  nomeAtendenteResponsavel?: string;

  prospectCodCliente?: string | null;

  isIntegrated?: boolean | null;

  origemCadastro?: number | null;

  tituloCoringa1Cliente?: string | null;
}

export default class VariableColumnModel {
  column = '';

  description = '';

  id = -1;

  cardsList = Array<string>();

  position!: number;

  constructor(variableName: string, description: string, cardsList: Array<string>) {
    this.column = variableName;
    this.description = description;
    this.cardsList = cardsList;
  }
}

import { injectable } from 'inversify-props';
import BaseService from '@/services/base-service';
import CityModel from '@/models/city.model';
import { RequestConfig } from '@/interfaces/request-config.interface';

@injectable()
export default class CityService extends BaseService {
  async getCityProspect(idProspect: number | undefined, codCliente: string | undefined): Promise<CityModel> {
    const config: RequestConfig = {
      params: {
        idProspect,
        codCliente,
      },
    };
    return (await this.httpService.get(CityModel, `${this.getApiPath()}/prospect/find-city`, config)) as CityModel;
  }
}

export default class VariableTextModel {
  variableName = '';

  description = '';

  cardsList = Array<string>();

  constructor(variableName: string, description: string, cardsList: Array<string>) {
    this.variableName = variableName;
    this.description = description;
    this.cardsList = cardsList;
  }
}

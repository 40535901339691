import { injectable } from 'inversify-props';
import { forEach } from 'lodash';
import dayjs from '@/plugins/dayjs';
import BaseService from '@/services/base-service';
import ClientModel from '@/models/crm/client.model';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import { RequestConfig } from '@/interfaces/request-config.interface';
import ProspectModel from '@/models/crm/prospect.model';
import GroupedSalesForceInfosModel from '@/models/b2sp/grouped-sales-force-infos.model';
// import ContactModel from '@/models/crm/contact.model';

@injectable()
export default class ClientService extends BaseService {
  async quickSearch(keyword: string, type: ClientTypeEnum): Promise<ClientModel[]> {
    const config: RequestConfig = {
      params: {
        term: encodeURIComponent(keyword).toString(),
        type,
      },
    };

    if (type === ClientTypeEnum.Prospect) {
      const result = (await this.httpService.get(
        ProspectModel,
        `${this.getApiPath()}/crm/quick-search`,
        config,
      )) as ProspectModel[];
      return result.map((x) => x.clientModel);
    }

    return (await this.httpService.get(ClientModel, `${this.getApiPath()}/crm/quick-search`, config)) as ClientModel[];
  }

  async advancedSearch(params: IKeyValue<string | null>, type: ClientTypeEnum): Promise<ClientModel[]> {
    const queryParams: string[] = [];

    forEach(params, (value: string | null, key: string) => {
      if (value) {
        queryParams.push(`${key}=${value}`);
      }
    });

    if (type === ClientTypeEnum.Prospect) {
      const result = (await this.httpService.get(
        ProspectModel,
        `${this.getApiPath()}/crm/advanced-search?type=${type}&${queryParams.join('&')}`,
      )) as ProspectModel[];

      return result.map((x) => x.clientModel);
    }

    return (await this.httpService.get(
      ClientModel,
      `${this.getApiPath()}/crm/advanced-search?type=${type}&${queryParams.join('&')}`,
    )) as ClientModel[];
  }

  async getClientContacts(clientCnpj: string): Promise<Array<string>> {
    const config: RequestConfig = {
      params: {
        clientCnpj,
      },
    };

    return (await this.httpService.get(String, `${this.getApiPath()}/crm/client-contacts`, config)
    ) as Array<string>;
  }

  async get(clientId: string, type: ClientTypeEnum): Promise<ClientModel> {
    const config: RequestConfig = {
      params: {
        clientId: clientId.replace(/\D/g, ''),
        type,
      },
    };

    if (type === ClientTypeEnum.Prospect) {
      const result = (await this.httpService.get(
        ProspectModel,
        `${this.getApiPath()}/crm/general-data`,
        config,
      )) as ProspectModel;

      return result.clientModel;
    }

    return (await this.httpService.get(ClientModel, `${this.getApiPath()}/crm/general-data`, config)) as ClientModel;
  }

  async quickSearchIgsClient(keyword: string): Promise<ClientModel[]> {
    return (await this.httpService.get(
      ClientModel,
      `${this.getApiPath()}/crm/quick-search-igs-client?term=${encodeURIComponent(keyword).toString()}`,
    )) as ClientModel[];
  }

  async getIgsClient(clientId: string): Promise<ClientModel> {
    return (await this.httpService.get(
      ClientModel,
      `${this.getApiPath()}/crm/igs-client?cnpj=${clientId.replace(/\D/g, '')}`,
    )) as ClientModel;
  }

  async getSummary(clientId: string, type: ClientTypeEnum, prospectForm = false): Promise<ClientModel> {
    const config: RequestConfig = {
      params: {
        clientId,
        type,
        prospectForm,
      },
    };

    if (type === ClientTypeEnum.Prospect) {
      const result = (await this.httpService.get(
        ProspectModel,
        `${this.getApiPath()}/crm/summary-data`,
        config,
      )) as ProspectModel;
      return result.clientModel;
    }

    return (await this.httpService.get(ClientModel, `${this.getApiPath()}/crm/summary-data`, config)) as ClientModel;
  }

  async saveParticularities(
    cnpjCpf: string | null,
    prospectId: string | null,
    clientType: string,
    particularities: string,
  ): Promise<void> {
    const config: RequestConfig = {
      params: {
        cnpjCpf,
        prospectId,
        clientType,
        particularities,
      },
    };
    await this.httpService.post(
      ClientModel,
      `${this.getApiPath()}/crm/save-particularities`, {}, config,
    );
  }

  async getSalesForceInfos(cnpjCpf: string | null): Promise<GroupedSalesForceInfosModel[]> {
    const config: RequestConfig = {
      params: {
        cnpjCpf,
      },
    };
    const teste = await this.httpService.get(
      GroupedSalesForceInfosModel,
      `${this.getApiPath()}/crm/campos-customizados-fv`,
      config,
    ) as GroupedSalesForceInfosModel[];

    return teste;
  }

  static generateClientExportFilename(date: Date): string {
    return `Empresa_Clientes_${dayjs(date).format('YYYY-MM-DD_HH-mm-ss')}.xlsx`;
  }

  static generateProspectExportFilename(date: Date): string {
    return `Empresa_Prospects_${dayjs(date).format('YYYY-MM-DD_HH-mm-ss')}.xlsx`;
  }

  async integrateSingleClient(cnpj: string): Promise<string> {
    const config: RequestConfig = {
      params: {
        cnpj,
      },
    };

    return (await this.httpService.post(String, `${this.getApiPath()}/crm/integrate-client`, {}, config)) as string;
  }
}
